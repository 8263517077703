import React, { useEffect, useState } from "react";
import { render } from 'react-dom';
import { FC } from "react";
import { useChatContext } from "../../services/contexts/chat-context";
import { CHAT_APP_ID } from "../../types/global";
import { LIVE_PERSON_SCRIPT_ID } from "../../enums/live-person";
import { LoadingSpinner } from "../ui-elements/loading-spinner.component";
import { loadScriptTag } from "../../utils/utility";
import { SubbrandDictionary } from "../../enums/brand-dictionary";
import { ActionID, AppIdentifiers } from "@sparkware/uc-sdk-core/lib/apps/app-enums";
import { AttatchIconSVG } from "../../resources/SVG/liveperson-attatch-icon";
import { SendIconSVG } from "../../resources/SVG/liveperson-send-icon";
import { ChatOpenMode } from "@sparkware/uc-sdk-core";

interface ILivePersonEventData {
  state: string;
}

export const ChatLivePersonComponent: FC<any> = (props) => {
  const {
    messageBroker,
    chatData,
    resourceService,
    playerService,
    authService,
    logger,
    livePersonService,
    applicationService
  } = useChatContext().services;
  messageBroker.initSucceded(CHAT_APP_ID);

  let deviceClass = "";

  const isAuthenticated = chatData.isPlayerAuthenticated;
  const cid = chatData.playerData?.cid.toString() ?? "";
  const subBrandId = chatData.chatInitParameters?.applicationContext.subBrandID;
  const correlationId = chatData.chatInitParameters?.applicationContext.launchInfo.businessCorrelationID;
  const sequentialCorrelationID = applicationService.sequentialCorrelationID;
  const services = useChatContext().services;
  const timeoutDuration = 30000

  function makeChatVisible(lpWindow: Element) {
    const messagesContainer =
      document.getElementsByClassName("lpc_transcript")[0];

    if (!messagesContainer?.childNodes.length) {
      setTimeout(() => makeChatVisible(lpWindow), 10);
      return;
    }

    messageBroker.initSucceded(CHAT_APP_ID);
    props.setIsLPChatVisible(true);
    lpWindow.classList.add("lp_visible");
    logger.info("LP chat Visible for User", {lpTag: window.lpTag});
    replaceIcons();
    toggleAttatchmentButton();
    removeFixedAndHiddenBody();
    if(chatData.chatOpenStartTime ) {
      const endTime = performance.now();
      const chatOpenTime = endTime - chatData.chatOpenStartTime;
      logger.info(`LP Chat loaded in ${chatOpenTime} miliseconds`, {lpTag: window.lpTag});
    }

    if(shouldAddKeyboardEventListeners()){
        addKeyboardEventListeners("370px");
    }

    const desktopDeviceType = 3;
    if(chatData.chatInitParameters?.deviceInfo.deviceType != desktopDeviceType &&
      chatData.chatInitParameters?.deviceInfo.OS?.toLowerCase() !== "android" &&
      (!chatData.chatInitParameters?.deviceInfo.isNative && !chatData.chatInitParameters?.deviceInfo.isHybrid)) {
      addKeyboardScrollEventListener();
    }

    if (messagesContainer.addEventListener) {
      messagesContainer.addEventListener('click', interceptClickEvent);
    }
    
  }

  function addKeyboardScrollEventListener(): void{
    const casinoSubbrands = [111, 112, 148, 46, 82, 113, 119, 125, 129, 137, 141, 151, 152, 154, 156, 159];
    const casinoCOMBrandId = 0;

    const input = document.getElementsByClassName("lpview_form_textarea")[0];

    if(casinoSubbrands.includes(chatData.channel?.subBrand as number) || chatData.channel?.brand === casinoCOMBrandId){
      addKeyboardEventListeners("250px");
    } 
    
    input?.addEventListener("focusin", () => {
      setTimeout(() => {
        if(!casinoSubbrands.includes(chatData.channel?.subBrand as number) && chatData.channel?.brand != casinoCOMBrandId){
          window.scrollTo(0, 250);
        }
      }, 500);
    })
  }
 
  function shouldAddKeyboardEventListeners(): boolean{
    const mrgSubbrands = [152, 154, 156, 159];
    const casinoSubbrands = [0, 111, 112, 148, 46, 82, 113, 119, 125, 129, 137, 141, 151];

    if(!chatData.chatInitParameters?.deviceInfo.isNative && !chatData.chatInitParameters?.deviceInfo.isHybrid){
      return false;
    }

    if(mrgSubbrands.includes(chatData.channel?.subBrand as number)){
      return true;
    }

    if(casinoSubbrands.includes(chatData.channel?.subBrand as number)){
      return true;
    }

    return false;
  }

  function removeFixedAndHiddenBody() {
    const clientBody = document.querySelectorAll('body')[0];
    if (clientBody) {
      clientBody.style.overflow = clientBody.style.overflow === 'hidden' ? '' : clientBody.style.overflow;
      clientBody.style.position = clientBody.style.position === 'fixed' ? '' : clientBody.style.position;
    }

    const metaViewPort = document.querySelector('meta[name="viewport"]');
    if (metaViewPort && (metaViewPort as HTMLMetaElement).content.indexOf('viewport-fit') < 0){
      (metaViewPort as HTMLMetaElement).content += ", viewport-fit=cover";
    }
  }
 
  function addKeyboardEventListeners(height: string){
    const input = document.getElementsByClassName("lpview_form_textarea")[0];

    const emptyDiv = document.createElement('div');
    emptyDiv.style.height = height;
    const chatDiv = chatData.chatInitParameters?.applicationContext.launchInfo.containerID;
    
    if(chatData.chatInitParameters?.deviceInfo.OS?.toLowerCase() !== "android"){
      localStorage.setItem("keyboardHasOpened", "false");
    }

    input?.addEventListener("focusin", () => {
      if(localStorage.getItem("keyboardHasOpened") === "false"){
        localStorage.setItem("keyboardHasOpened", "true");
        emptyDiv.style.height = "50px";
      } else {
        emptyDiv.style.height = height;
      }
        
      if(chatDiv){
        const chatContainer = document.getElementById(chatDiv)?.firstChild as HTMLElement;
        chatContainer?.appendChild(emptyDiv);
        chatContainer.scrollTop = chatContainer.scrollHeight;
      }
    })

    input?.addEventListener("focusout", () => {

      if(chatDiv && emptyDiv.parentNode === document.getElementById(chatDiv)?.firstChild){
        document.getElementById(chatDiv)?.firstChild?.removeChild(emptyDiv);
      }
    })
  }

  function interceptClickEvent(e: any) {
    let target = e.target || e.srcElement;

    if (target.tagName === 'A') {
      e.preventDefault();

      let href = target.getAttribute('href');

      messageBroker.performAction(
        AppIdentifiers.CRChat,
        ActionID.openLink,
        undefined,
        {
          url: href,
          openExternally: true,
        }
      );
    }
  }

  function closeChatContainer(data: any, eventInfo: any) {
    logger.info("LP chat container closed", {lpTag: window.lpTag});
    messageBroker.appClosed(CHAT_APP_ID, chatData.chatInitParameters?.applicationContext.launchInfo.businessCorrelationID!);
  }

  function moveLPWindow() {
    let chatDiv = chatData.chatInitParameters?.applicationContext.launchInfo.containerID;

    if (chatDiv) {

      const waitForContainerPromise = new Promise<void>((resolve, reject) => {
        const timeoutDuration = 30000;
        let timer: number | NodeJS.Timeout;

        timer = setTimeout(() => {
          const error = new Error("LP - Waiting for container timed out")
          logger.error(`LP - Waiting for container timed out. ${error}`, {error: error});
          reject(error);
        }, timeoutDuration);

        const checkContainerStartedPromise = () => {
          deviceClass = livePersonService.getDeviceClass();
          if (!document.getElementsByClassName(deviceClass).length || !document.getElementById(chatDiv as string)?.firstChild) {
            console.log("checkContainerStartedPromiseLoop");
            setTimeout(checkContainerStartedPromise, 250);
          } else {
            clearTimeout(timer);
            resolve();
          }
        };

        checkContainerStartedPromise();
      });

      waitForContainerPromise.then(() => {
        logger.info("LP window moved in our container", {lpTag: window.lpTag});
        deviceClass = livePersonService.getDeviceClass();
        const lpWindow = document.getElementsByClassName(deviceClass)[0];
        if (chatDiv && document.getElementById(chatDiv)) {
          document.getElementById(chatDiv)?.firstChild?.appendChild(lpWindow);
        }
        makeChatVisible(lpWindow);
      })
    }
  }

  function getEgagementId(): string | undefined {
    if (isAuthenticated){
      if(chatData.chatOpenMode === ChatOpenMode.SOF){
        return chatData.chatSettings?.engagementIDProactive;
      } else {
        return chatData.chatSettings?.engagementIDAuthenticated;
      }
    } else {
      return chatData.chatSettings?.engagementIDUnauthenticated;
    }
  }

  function openLPChat() {
    if (!window.lpTag.started) {
      waitForLPTagPromise().then(() => {
        waitForEngagementStatePromise().then(() => {
          let engagementId = getEgagementId();
          logger.info("LP engagement loaded successfully", {lpTag: window.lpTag});
          window.lpTag.taglets.rendererStub.click(engagementId);
          moveLPWindow();
        })
          .catch((error) => {
            logger.error(`LP Engagement info failed to obtain. ${error}`, { error: error, lpTag: window.lpTag });
            //logger.info(`LpTag log on error`, {lpTag: window.lpTag});

          });
      })
        .catch((error) => {
            logger.error(`LP Tag failed to load. ${error}`, { error: error, lpTag: window.lpTag });
         // logger.info(`LpTag log on error`, {lpTag: window.lpTag});
          
        });
    }
    else {
      //lpTag already started > chat has been closed and opened again
      waitForEngagementStatePromise().then(() => {
        let engagementId = getEgagementId();
        logger.info("LP engagement loaded successfully", {lpTag: window.lpTag});
        window.lpTag.taglets.rendererStub.click(engagementId);
        moveLPWindow();
      })
        .catch((error) => {
          logger.error(`LP Engagement info failed to obtain. ${error}`, { error: error, lpTag: window.lpTag });
         // logger.info(`LpTag log on error`, {lpTag: window.lpTag});
         
        });
    }
  }

  function reopenLPChat() {
    console.log(`inside reopenLPChat func`)
    //#region definitions
    const timeoutDuration = 30000; // 30 seconds in milliseconds
    const waitForLPTagPromise = new Promise<void>((resolve, reject) => {
      let timer:  number | NodeJS.Timeout;

      timer = setTimeout(() => {
        const error = new Error("LP Tag loading timed out")
        logger.error(`LP Tag loading timed out ${error}`, {error: error, lpTag: window.lpTag})
        reject(error);
      }, timeoutDuration);

      const checkLpTagStarted = () => {
        if (window.lpTag.started) {
          clearTimeout(timer);
          resolve();
        } else {
          console.log("checkLpTagStartedLoop");
          setTimeout(checkLpTagStarted, 250);
        }
      };

      checkLpTagStarted();
      console.log(`after checkLPTagStarted in reopenLPChat func`)
    });
    const waitForEngagementStatePromise = new Promise<void>((resolve, reject) => {
      let timer:  number | NodeJS.Timeout;
      let engagementId = getEgagementId();
      let isRejected = false;

      timer = setTimeout(() => {
        if (!isRejected) {
        isRejected = true;
        const error = new Error("LP Engagement state failed to complete")
        logger.error(`LP Engagement state failed to complete ${error}`, {error: error, lpTag: window.lpTag})
        reject(error);
        }
      }, timeoutDuration);

      const checkEngagementStateStarted = () => {
        if (isRejected) return;

        const engagementState =
          window.lpTag.taglets?.rendererStub?.getEngagementInfo(
            engagementId
          )?.state;

        //let chatLoaded = null;
        //if (typeof window.lpTag.events.hasFired === 'function') //This method doesn't load fast enough sometimes so we need to check for it
        //  chatLoaded = window.lpTag.events.hasFired().filter((event: any) => event.appName === 'lpUnifiedWindow' && event.data && event.data.state === 'interactive' || 'waiting');

        if (engagementState) {
          clearTimeout(timer);
          resolve();
        } else {
          console.log("checkEngagementStatePromiseLoop");
          setTimeout(checkEngagementStateStarted, 250);
        }
      };

      checkEngagementStateStarted();
      console.log(`after checkEngagementStateStarted in reopenLPChat func`)
    });
    //#endregion 

        waitForEngagementStatePromise.then(() => {
        let engagementId = getEgagementId();
        logger.info("LP engagement loaded successfully", {lpTag: window.lpTag});
        window.lpTag.taglets.rendererStub.click(engagementId);
        moveLPWindow();
      })
        .catch((error) => {
          logger.error(`LP Engagement info failed to obtain. ${error}`, { error: error, lpTag: window.lpTag});
         // logger.info(`LpTag log on error`, {lpTag: window.lpTag});
           
        });
  }



  const lpMethods = {
    lpGetToken: function (callback: any) {
      if (typeof callback == "function") {
        authService
          .GetLivePersonAuthCode(cid)
          .then((authCode: string) => {
            callback({
              ssoKey: authCode,
              redirect_uri: "http://localhost:8739/",
            });
          })
          .catch((err: any) => {
            logger.error(`LP GetLivePersonAuthCode failed ${err}`, {error: err})
            callback(err, "error reason");
          });
      }
    },
    livePersonIdentity: (callback: any) => {
      try {
        if (callback && typeof callback === "function") {
          callback({
            iss: chatData.environmentSettings.authorizationServiceApiUrl,
            acr: "loa1",
            sub: parseInt(cid),
          });
        }
      } catch (error) {
        logger.error(`Error in livePersonIdentity ${error}`, {error: error, lpTag: window.lpTag})
        console.error("Error in livePersonIdentity:", error);
      }
    },    
  };

  const bindLPEvents = () => {
    window.lpTag.events.bind("lpUnifiedWindow", "windowClosed", closeChatContainer);
  };


  const initLivePerson = () => {
    if (!window.lpTag) {
      const error = new Error("Error loading LP chat!")
      logger.error(`LP Tag is missing! ${error}`, {error: error});
      throw error;
    }
    
    window.lpTag.site = chatData.chatSettings?.isUSBrand ? chatData.environmentSettings.livePersonUSSiteId : chatData.environmentSettings.livePersonDublinSiteId;
    window.lpTag.sdes.push({
      "type": "cart",
      "numItems": subBrandId,
      "currency": correlationId,
      "total": sequentialCorrelationID
    });

    bindLPEvents();

    //Authorization
    enableLPAuthentication(isAuthenticated, lpMethods);

    window.lpTag.init(true);
  };

  function LoadAndInitLPSdk() {
    try {
      if (!window.lpTag?.started) {
        logger.info("LP Tag Started", {lpTag: window.lpTag});
        loadScriptTag(
          LIVE_PERSON_SCRIPT_ID,
          resourceService.resolveResourcePath("/live-person.js")
        )
          .then(() => {
            logger.info("LP Tag Loaded successfully", { lpTag: window.lpTag });
            initLivePerson();
            openLPChat();
          })
          .catch((error) => {
            logger.error("LP Tag load failed", { lpTag: window.lpTag });
            console.warn("Live Person load failed.");
          });
      } else {
        console.log("lpTag.newPage " + window.location.href);
        enableLPAuthentication(isAuthenticated, lpMethods);
        window.lpTag.newPage(window.location.href);
        reopenLPChat();
      }
    } catch (error) {
      console.error("Error in LoadAndInitLPSdk:", error)
      logger.error("Error in LoadAndInitLPSdk:", {error: error, lpTag: window.lpTag});
    }
  }
  

  function replaceIcons() {
    replaceIcon('lpc_composer__file-attachment-button-asset', <AttatchIconSVG/>);
    replaceIcon('lp_send-icon', <SendIconSVG/>);
  }
  
  function replaceIcon(className: string, component: any)
  {
    const attatchSvg = document.getElementsByClassName(className);
    if (attatchSvg.length > 0) {
      const targetSVGElement = attatchSvg[0];
  
      const dummyDiv = document.createElement('div');
      render(component, dummyDiv);
  
      targetSVGElement.innerHTML = dummyDiv.innerHTML;
    }
  }

  function toggleAttatchmentButton()
  {
    const attatchBtn = document.getElementsByClassName('lp_file-icon')[0];

    if (attatchBtn) {
      if (chatData.chatSettings?.showAttachmentButton === false) {
          attatchBtn.remove();
      }
  }
}

  const waitForLPTagPromise = (): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      let timer:  number | NodeJS.Timeout;
      const checkLpTagStarted = () => {
        if (window.lpTag.started) {
          clearTimeout(timer);
          resolve();
        } else {
          console.log("checkLpTagStartedLoop");
          setTimeout(checkLpTagStarted, 250);
        }
      };

    timer = setTimeout(() => {
      const error = new Error("LP Tag loading timed out")
      logger.error(`LP Tag loading timed out ${error}`, {error: error, lpTag: window.lpTag})
      reject(error);
    }, timeoutDuration);

    checkLpTagStarted();
  });
}

  const waitForEngagementStatePromise = ():Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      let timer:  number | NodeJS.Timeout;
      let engagementId = getEgagementId();
      let isRejected = false;
      const checkEngagementStateStarted = () => {
        if (isRejected) return;
        
        console.log(chatData);
        const engagementState =
          window.lpTag.taglets?.rendererStub?.getEngagementInfo(
            engagementId
          )?.state;
  
        let chatLoaded = null;
        if (typeof window.lpTag.events.hasFired === 'function') //This method doesn't load fast enough sometimes so we need to check for it
          chatLoaded = window.lpTag.events.hasFired().filter((event: any) => event.appName === 'lpUnifiedWindow' && event.data && event.data.state === 'interactive');
          
          if (engagementState) {
            clearTimeout(timer);
            resolve();
          } else if (chatLoaded.length > 0) {
            clearTimeout(timer);
            window.lpTag.newPage(window.location.href);
            resolve();
          } else {
            console.log("checkEngagementStatePromiseLoop");
            setTimeout(checkEngagementStateStarted, 250);
          }
      };
      timer = setTimeout(() => {
        if (!isRejected) {
        isRejected = true;
        const error = new Error("LP Engagement state failed to complete")
        logger.error(`LP Engagement state failed to complete ${error}`, {error: error, lpTag: window.lpTag})
        reject(error);
        }
      }, timeoutDuration);
  
    checkEngagementStateStarted();
    });
  };

  useEffect(() => {
    LoadAndInitLPSdk();
  }, []);

  
  return <div id="ChatLivePersonComponent">
    {chatData.chatOpenMode === ChatOpenMode.SOF  && <div id={chatData.chatSettings?.htmlEngagementIDProactive}></div>}
    {(isAuthenticated && chatData.chatOpenMode !== ChatOpenMode.SOF) && <div id={chatData.chatSettings?.htmlEngagementIDAuthenticated}></div>}
    {!isAuthenticated && <div id={chatData.chatSettings?.htmlEngagementIDUnauthenticated}></div>}

    {!props.isLPChatVisible && <LoadingSpinner />}
  </div>;
};
function enableLPAuthentication(isAuthenticated: boolean | undefined, lpMethods: { lpGetToken: (callback: any) => void; livePersonIdentity: (callback: any) => void; }) {
  if (isAuthenticated) {
    window.lpTag.identities = [];
    window.lpTag.identities.push(lpMethods.livePersonIdentity);
  }
  window.lpGetToken = lpMethods.lpGetToken;
}

